import Layout from "components/layout/MainLayout"
import { Button } from "components/shared/panda/Button"
import Title from "components/shared/Title"
import SettingsContextProvider from "context/SettingsContext"
import { Settings } from "schemas"
import { Center, Container, styled, VStack } from "styled-system/jsx"

type Props = {
  settings: Settings
}

function Error404Page({ settings }: Props) {
  return (
    <SettingsContextProvider settings={settings}>
      <Layout page={{ whiteBackground: true }}>
        <styled.div
          css={{
            textAlign: "center",
            pt: "80px",
            pb: "120px",
            bg: "linear-gradient(to bottom, transparent 50%, rgba(140, 96, 250, .4))",
          }}>
          <Container>
            <Center>
              <styled.img alt="" src="/404.svg" />
            </Center>

            <VStack gap={8}>
              <Title
                as="h3"
                css={{
                  fontFamily: "heading",
                  fontSize: "56px",
                  fontWeight: 600,
                  lineHeight: "136%",
                }}>
                ¡Lo sentimos!
              </Title>
              <Title
                as="h4"
                css={{
                  color: "#373737",
                  fontSize: "32px",
                  fontWeight: 600,
                  lineHeight: "133%",
                  maxW: "922px",
                }}>
                No encontramos la página que buscas
              </Title>
              <styled.p
                css={{
                  color: "#373737",
                  fontSize: "24px",
                  lineHeight: "133%",
                  maxW: "922px",
                }}>
                ¡Vuelve a nuestra web o contacta con nosotros e intentaremos
                ayudarte!
              </styled.p>
              <VStack gap={8}>
                <Button
                  size="medium"
                  variant="filled"
                  fillColor="primary"
                  color="white"
                  href="/">
                  Ir al inicio
                </Button>
                <Button
                  size="medium"
                  variant="filled"
                  fillColor="tertiary"
                  href="mailto:info@vixiees.com">
                  info@vixiees.com
                </Button>
              </VStack>
            </VStack>
          </Container>
        </styled.div>
      </Layout>
    </SettingsContextProvider>
  )
}

export default Error404Page
